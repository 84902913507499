<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.print_conclusion") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="ml-4 mr-4 mt-3">
      <table
        style="background: #fff"
        class="table table-bordered table-hover"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="setPagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.doctor_id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.service_id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.date_time"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :sort="sort"
              :column="columns.updated_at"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :sort="sort"
              :column="columns.created_at"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :sort="sort"
              :column="columns.settings"
              @c-change="updateSort"
            ></crm-th>
          </tr>
          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                class="id_input"
                v-model="filterForm.id"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <!-- <th v-if="columns.patient_id.show">
                            <el-input size="mini" v-model="filterForm.patient_id" :placeholder="columns.patient_id.title" clearable>
                            </el-input>
                        </th> -->
            <th v-if="columns.doctor_id.show">
              <el-input
                size="mini"
                v-model="filterForm.doctor_id"
                :placeholder="columns.doctor_id.title"
                clearable
              >
              </el-input>
            </th>
            <th v-if="columns.service_id.show">
              <el-input
                size="mini"
                v-model="filterForm.service_id"
                :placeholder="columns.service_id.title"
                clearable
              >
              </el-input>
            </th>
            <th v-if="columns.date_time.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.date_time"
                :placeholder="columns.date_time.title"
                size="mini"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                size="mini"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                size="mini"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="item in list" :key="item.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ item.id }}</td>
            <!-- <td v-if="columns.patient_id.show">{{ item.patient ? (item.patient.first_name + ' ' + item.patient.surname ) : '' }}</td> -->
            <td v-if="columns.doctor_id.show">
              {{
                item.doctor
                  ?  (item.doctor.surname ? item.doctor.surname : "")+
                    " " +
                    item.doctor.name
                  : ""
              }}
            </td>
            <td v-if="columns.service_id.show">
              {{ item.service ? item.service.name : " - " }}
            </td>
            <td v-if="columns.date_time.show">
              {{ item.date_time }}
            </td>
            <td v-if="columns.updated_at.show">
              {{ item.updated_at }}
            </td>
            <td v-if="columns.created_at.show">
              {{ item.created_at }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-button
                v-can="'printCenter.print'"
                type="success"
                @click="print('conclusion/print', item.id)"
                >{{ $t("message.printing") }}</el-button
              >
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import print from "@/utils/mixins/print";
export default {
  mixins: [print],
  name: "conclusionsList",
  props: ["id"],
  components: {},
  data() {
    return {
      drawerShow: false,
      reopenShow: false,
      loadingData: false,
      list: [],
      filterForm: {
        id: null,
        service_id: null,
        doctor_id: null,
        patient_id: "",
        form_template_id: null,
        search: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      columns: "conclusions/columns",
      pagination: "conclusions/pagination",
      filter: "conclusions/filter",
      sort: "conclusions/sort",
      model: "orders/relation_model",
    }),
  },
  created() {
    this.debouncedGetAnswer = _.debounce(this.show, 800);
  },
  async mounted() {},
  watch: {
    id: {
      handler: function (newVal, oldVal) {
        this.show();
      },
      deep: true,
      immediate: true,
    },
    pagination: {
      handler: function (newVal, oldVal) {
        // this.debouncedGetAnswer()
      },
      deep: true,
    },
    filterForm: {
      handler: function (newVal, oldVal) {
        this.debouncedGetAnswer();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "conclusions/index",
      updateSort: "conclusions/updateSort",
      updateFilter: "conclusions/updateFilter",
      updateColumn: "conclusions/updateColumn",
      updatePagination: "conclusions/updatePagination",
      showModel: "conclusions/patientConculation",
      empty: "conclusions/empty",
    }),
    async show() {
      const query = {
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
        patient: true,
        service: true,
        patient_id: this.id,
      };
      this.$loadingCursor("wait");
      this.loadingData = true;
      await this.showModel(query)
        .then((res) => {
          this.loadingData = false;
          this.list = res.data.data;
          this.$loadingCursor("default");
        })
        .catch((err) => {
          this.loadingData = false;
          this.$loadingCursor("default");
        });
    },
    setPagination(page) {
      this.updatePagination(page).then((res) => {
        this.show();
      });
    },
    emptyModel() {
      this.empty();
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerShow" });
    },
  },
};
</script>
